import { useTablet } from "@modules/hooks/useMedia";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import Plan from "@public/images/ttbb/ic_plan_ou_b_g90.svg";
import My from "@public/images/ttbb/ic_my_ou_b_g90.svg";
import { getGlobaleurSso } from "@modules/api/Sso";
import { getAccessToken } from "@modules/utils/AccessToken";
export default function MainNav({
  token,
  type
}) {
  const isTablet = useTablet();
  const router = useRouter();
  const {
    t
  } = useTranslation('common');
  const {
    locale
  } = useRouter();
  const myIcon = `bg-[url('/images/ttbb/ic_my_ou_b_g90.svg')]`;
  const isLoggedIn = typeof window !== undefined && getAccessToken() !== '';
  const goPage = type => {
    switch (type) {
      case 'plan':
        if (isLoggedIn) {
          getGlobaleurSso(locale).then(data => {
            if (data.body.code == 'SUCCESS') {
              router.push(data.body.item);
            } else {
              router.push(process.env.NEXT_PUBLIC_TTBB_PLAN_URL);
            }
          });
        } else {
          router.push(process.env.NEXT_PUBLIC_TTBB_PLAN_URL);
        }
        return;
      case 'my':
        if (token || isLoggedIn) {
          return router.push('/mypage');
        } else {
          return router.push('/signin');
        }
      default:
        alert('메뉴 설정이 없습니다.');
    }
  };
  return <>
      {!isTablet && <>
          <button onClick={() => goPage(type)} className={`inline-block h-[72px] min-w-[72px] pt-[40px] text-center 
            ${type === 'plan' && "bg-[url('/images/ttbb/ic_plan_ou_b_g90.svg')]"} ${type === 'my' && myIcon}
         
          C_12_100_Regular hover:C_12_100_Bold bg-[length:32px_32px] bg-[center_12px] bg-no-repeat 
                        text-Gray-500 hover:rounded-[8px] hover:bg-Bg-OffWhite hover:text-Gray-800 tablet:text-[10px]`}>
            <span className={`${router.pathname === '/' && type === 'home' && 'font-bold text-Gray-800'} `}>
              {t(`header.btn.${type}`)}
            </span>
          </button>
        </>}

      {isTablet && <div className="flex justify-center cursor-pointer">
            {type === 'my' && <button onClick={() => goPage(type)} className={`flex flex-col items-center justify-center`}>
                <My className="h-[24px] w-[24px]" />
                <span className={`MM_10_400_Regular text-Gray-500 tablet:m-0 mobile:text-[10px]`}>
                  {t(`header.btn.${type}`)}
                </span>
              </button>}
            {type === 'plan' && <button onClick={() => goPage(type)} className="flex flex-col items-center justify-center">
                <Plan className="h-[24px] w-[24px]" />
                <span className={`MM_10_400_Regular  text-Gray-500 tablet:m-0 mobile:text-[10px]`}>
                  {t(`header.btn.plan`)}
                </span>
              </button>}
          </div>}
    </>;
}