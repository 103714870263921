import Logo from "./main/Logo";
import { useRouter } from "next/router";
export default function SignupHeader() {
  const router = useRouter();
  const icon = router.query.provider ? "tablet:bg-[url('/images/common/ic_arrow_left_ou_b_g90.svg')]" : "tablet:bg-[url('/images/common/ic_cancel_ou_b_g90.svg')]";
  return <header className={`subHeader sticky left-0 top-0 w-full bg-white z-[12] shadow-login tablet:shadow-none`}>
      <div className={`header flex items-center pr-[56px] pl-[80px] justify-between transition-all duration-[0.3s] h-[112px] tablet:h-[56px] tablet:p-0 tablet:pl-[24px] tablet:h-[56px]`}>
        <Logo className={`tablet:hidden`} />
        <a onClick={() => {
        router.back();
      }} className={`txtHide btnCloseHd hidden tablet:block tablet:absolute tablet:left-0 tablet:top-0 tablet:w-[56px] tablet:h-[56px] 
                      ${icon} tablet:bg-no-repeat tablet:bg-[center_center]`}>
          Close
        </a>
      </div>
    </header>;
}