import MainHeader from "./MainHeader";
import Link from "next/link";
import { useKbpay } from "@modules/hooks/useDomain";
export default function MypageMainHeader({
  setIsLogout
}) {
  const isKbPay = useKbpay();
  return <>
      <MainHeader isShadow={true} className={`tablet:hidden`} setIsLogout={setIsLogout} />
      {!isKbPay && <header className="subHead tp1 hidden tablet:block
                   sticky left-0 top-0 w-full z-[12] bg-white
                   min-h-[56px] text-center">
        <h1 className="H6_18_100_Bold leading-[56px]">마이</h1>
        <Link href="/mypage/alarm" className={`txtHide btnMenu absolute w-[56px] h-[56px] right-0 top-0 
                      bg-[url('/images/common/ic_menu_ou_b_g90.svg')] bg-no-repeat bg-[center_center]`}>
          상단메뉴
        </Link>
      </header>}
    </>;
}