import { useRouter } from "next/router";
import Logo from "@components/layout/header/main/Logo";
export default function VerifyHeader({
  isApp,
  title
}) {
  const router = useRouter();
  if (isApp) return;
  return <header className="tablet:fixed tablet:top-0 tablet:left-0 w-full bg-white shadow-login tablet:shadow-none z-10">
      <div className={`relative flex items-center justify-between pr-[56px] pl-[80px] tablet:p-0 transition-all duration-[0.3s] h-[112px] tablet:h-[56px] `}>
        <Logo className={`tablet:hidden`} />
        <a onClick={() => router.back()} className={`absolute top-0 left-0 txtHide btnCloseHd hidden tablet:block flex-none w-[56px] h-[56px] 
                    bg-ic_arrow_left_ou_b_g90 bg-no-repeat bg-[center_center] cursor-pointer`}>
          Close
        </a>
        <h1 className="hidden tablet:block grow text-center H6_18_100_Bold leading-[56px]">{title}</h1>
      </div>
    </header>;
}