import MainHeader from "./MainHeader";
import { useRouter } from "next/router";
import dynamic from "next/dynamic";
const MediaQuery = dynamic(() => import("react-responsive"), {
  ssr: false
});
export default function MypageHeader({
  title,
  isLangPopup,
  setIsLangPopup,
  setIsLogout,
  isApp,
  mode
}) {
  const router = useRouter();
  return <>
      <MainHeader isShadow={true} isLangPopup={isLangPopup} setIsLangPopup={setIsLangPopup} setIsLogout={setIsLogout} className={"tablet:hidden"} />
      {!isApp && <MediaQuery maxWidth={1024}>
          <header className="subHead tp1 hidden tablet:block
                   sticky left-0 top-0 w-full z-[12] bg-white
                   min-h-[56px] text-center">
            <a onClick={() => {
          if (mode === "close") {
            if (window) {
              window.close();
            }
          } else {
            router.back();
          }
        }} className={`txtHide btnBack absolute w-[56px] h-[56px] block left-0 top-0 bg-no-repeat bg-[center_center]
          ${mode === "close" ? "bg-[url('/images/common/ic_cancel_ou_b_g90.svg')]" : "bg-ic_arrow_left_ou_b_g90"}`}>
              뒤로가기
            </a>
            {title && <h1 className="H6_18_100_Bold leading-[56px]">{title}</h1>}
          </header>
        </MediaQuery>}
    </>;
}