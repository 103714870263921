import Footer from "./footer";
import { useTranslation } from "next-i18next";
import TopBtn from "./TopBtn";
import MainHeader from "./header/MainHeader";
import SignupHeader from "./header/SignupHeader";
import LoginHeader from "./header/LoginHeader";
import { Contains } from "@modules/utils/Contains";
import MypageHeader from "./header/MypageHeader";
import MypageMainHeader from "./header/MypageMainHeader";
import CheckOutHeader from "./header/CheckOutHeader";
import MdHeader from "./header/MdHeader";
import React, { useEffect, useState } from "react";
import { tokenRefresh } from "@modules/api/Auth";
import { useRouter } from "next/router";
import { Cookies } from "react-cookie-consent";
import { clearToken, getDomain } from "@modules/utils/AccessToken";
import LogoutPopup from "../LogoutPopup";
import Toast from "../Toast";
import ProfileHeader from "./header/ProfileHeader";
import { setTbMeta } from "@modules/utils/Adsource";
import VerifyHeader from "@components/layout/header/VerifyHeader";
import { getMyProfile } from "@modules/api/Crew";
import ChannelTalk from "@components/script/ChannelTalk";
import { disableBodyScrollLock, enableBodyScrollLock } from "@modules/utils/BodyScrollLock";
import { isMobile } from "react-device-detect";
export default function zLayout({
  children,
  title,
  headerType,
  wrapClassName,
  openSearchBar,
  isLangPopup,
  setIsLangPopup,
  hideTopBtn,
  isHomeBarFixed,
  openSettingLayer,
  setIsWithdrawPopup,
  setOpenSettingLayer,
  mode,
  isApp,
  onOpenSharePopupHandler
}) {
  const {
    t
  } = useTranslation("common");
  const {
    locale
  } = useRouter();
  const router = useRouter();
  const [isLogout, setIsLogout] = useState(false);
  const [toastState, setToastState] = useState(false);
  useEffect(() => {
    const resizeEvent = () => {
      if (document.activeElement.tagName == "INPUT" || document.activeElement.tagName == "TEXTAREA") {
        document.activeElement.scrollIntoView(false);
      }
    };
    window.addEventListener("resize", resizeEvent);
    return () => {
      window.removeEventListener("resize", resizeEvent);
    };
  }, []);
  useEffect(() => {
    if (Cookies.get("logout") === "Y") {
      setToastState(true);
      Cookies.set("logout", "", {
        expires: -1,
        domain: getDomain()
      });
    }
    const access_token = Cookies.get("access_token");
    const refresh_token = localStorage.getItem("refresh_token");
    if (!access_token && refresh_token) {
      tokenRefresh(locale, {
        refresh_token: refresh_token
      }).then(data => {
        Cookies.set("token_type", data.body.token_type, {
          expires: data.body.expires_in / 60 / 60 / 24,
          domain: getDomain()
        });
        Cookies.set("access_token", data.body.access_token, {
          expires: data.body.expires_in / 60 / 60 / 24,
          domain: getDomain()
        });
        Cookies.set("refresh_token", data.body.refresh_token, {
          expires: data.body.refresh_token_expires_in / 60 / 60 / 24,
          domain: getDomain()
        });
        if (refresh_token) {
          localStorage.setItem("refresh_token", data.body.refresh_token);
        }
        getMyProfile(locale).then(profile => {
          Cookies.set("user_id", profile?.body.item.id, {
            expires: data.body.expires_in / 60 / 60 / 24,
            domain: getDomain()
          });
          router.reload();
        });
      }).catch(() => {
        clearToken();
        // if(IsTripbtozApp()){
        //   try{
        //     connectSignBridge();
        //   }catch(e){;}
        // }
        router.push("/");
      });
    }
  }, []);
  useEffect(() => {
    if (!router.isReady) return;
    setTbMeta(router, "");
  }, [router.isReady]);
  const [tokenPopupOpen, setTokenPopupOpen] = useState(false);
  useEffect(() => {
    const token = router.query['accessToken'] && router.query['accessToken'] !== 'null' || !!Cookies.get('access_token');
    setTokenPopupOpen(!token);
  }, [router]);
  useEffect(() => {
    if (window.ChannelIO) {
      window.ChannelIO('onShowMessenger', enableBodyScrollLock);
      window.ChannelIO('onHideMessenger', disableBodyScrollLock);
    }
  }, []);
  useEffect(() => {
    const chanelTalkFlag = isMobile ? headerType !== Contains.HOTEL && headerType !== Contains.CHECKOUT : true;
    const talkEl = document.getElementById("ch-plugin");
    if (talkEl) {
      talkEl.style.setProperty("display", `${!chanelTalkFlag ? "none" : ""}`, "important");
    }
  }, [headerType]);
  return <>
      <ChannelTalk />
      <div id="wrap" className={`transition-all delay-[0s] duration-[0.3s] ease-[ease]
                    ${headerType === Contains.MYPAGE || headerType === Contains.HOTEL ? "tablet:my-0 tablet:mx-auto tablet:flex tablet:min-h-full tablet:min-w-[320px] tablet:flex-col" : "mobile:my-0 mobile:mx-auto mobile:flex mobile:min-h-full mobile:min-w-[320px] mobile:flex-col"}
                    ${wrapClassName ?? ""}`}>
        {/*mobile:max-w-[420px]*/}

        {/* 상단 */}
        {(headerType === Contains.MAIN || headerType === Contains.SEARCH || headerType === Contains.HOTEL) && <MainHeader className={`${(headerType === Contains.HOTEL || headerType === Contains.CHECKOUT || headerType === Contains.SEARCH) && "tablet:hidden"}`} isShadow={headerType === Contains.MAIN || headerType === Contains.CHECKOUT} isRelative={headerType === Contains.HOTEL || headerType === Contains.SEARCH} setIsLogout={setIsLogout} openSearchBar={openSearchBar} />}
        {headerType === Contains.LOGIN && <LoginHeader openSettingLayer={openSettingLayer} setOpenSettingLayer={setOpenSettingLayer} />}
        {headerType === Contains.SIGNUP && <SignupHeader />}
        {headerType === Contains.MYPAGE_MAIN && <MypageMainHeader setIsLogout={setIsLogout} />}
        {(headerType === Contains.MYPAGE || headerType === Contains.CITY_STORY || headerType === Contains.WITHDRRAW) && <MypageHeader title={title} isLangPopup={isLangPopup} setIsLangPopup={setIsLangPopup} setIsLogout={setIsLogout} mode={mode} isApp={isApp} />}
        {headerType === Contains.PROFILE && <ProfileHeader setIsWithdrawPopup={setIsWithdrawPopup} setIsLogout={setIsLogout} />}
        {headerType === Contains.CHECKOUT && <CheckOutHeader setIsLogout={setIsLogout} />}
        {headerType === Contains.MD && <MdHeader setIsLogout={setIsLogout} isApp={isApp} onOpenSharePopupHandler={onOpenSharePopupHandler} />}
        {headerType === Contains.VERIFY && <VerifyHeader title={title} isApp={isApp} onOpenSharePopupHandler={onOpenSharePopupHandler} />}
        {/* // 상단 */}

        {/* 컨텐츠 */}
        {children}
        {/* // 컨텐츠 */}

        {/* 푸터 */}
        {(headerType === Contains.MAIN || headerType === Contains.HOTEL || headerType === Contains.MD || headerType === Contains.CITY_STORY) && <Footer />}

        {(headerType === Contains.MYPAGE_MAIN || headerType === Contains.CHECKOUT || headerType === Contains.MYPAGE || headerType === Contains.PROFILE || headerType === Contains.WITHDRRAW) && <Footer className={`tablet:hidden`} />}

        {headerType === 'header_hidden' && <Footer />}
        {/* // 푸터 */}

        {/* 하단 네비게이션 */}

        {!hideTopBtn && <TopBtn />}

        {isLogout && <LogoutPopup setIsLogout={setIsLogout} />}

        {toastState && <Toast toastText={t("mypage.logout.completed")} setToastState={setToastState} />}
      </div>
    </>;
}