import styles from "./Toast.module.css";
import React, { useEffect } from "react";
export default function Toast({
  toastText,
  setToastState,
  bottomClass
}) {
  useEffect(() => {
    setTimeout(function () {
      setToastState(false);
    }, 1500);
  });
  return <div className={`${styles.layerToss} bg-Gray-800
                  ${bottomClass || ""}`}>
      <p className="SB_14_150_Medium" dangerouslySetInnerHTML={{
      __html: toastText
    }} />
    </div>;
}