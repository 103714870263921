import React, { useEffect, useState } from "react";
import styles from "./LogoutPopup.module.css";
import { useTranslation } from "next-i18next";
import { clearToken, getDomain } from "../../modules/utils/AccessToken";
import { useRouter } from "next/router";
import { Cookies } from "react-cookie-consent";
import { IsTripbtozApp } from "../../modules/utils/TripbtozApp";
import { connectSignBridge } from "../../modules/utils/connectSignBridge";
import { disableBodyScrollLock, enableBodyScrollLock } from "../../modules/utils/BodyScrollLock";
export default function LogoutPopup({
  setIsLogout
}) {
  const {
    t
  } = useTranslation("common");
  const router = useRouter();
  useEffect(() => {
    enableBodyScrollLock();
    return function () {
      disableBodyScrollLock();
    };
  }, []);
  const LogoutHandler = () => {
    clearToken();
    Cookies.set("logout", "Y", {
      expires: 0.1,
      domain: getDomain()
    });
    setIsLogout(false);
    if (router.pathname === "/") {
      router.reload();
    } else {
      router.push("/");
    }
  };
  return /*알럿 레이어*/(
    <div className={styles.layerWrap}>
      <div className={styles.bgModal70}></div>
      <div className={styles.layerAlert}>
        <section>
          <p className="H5_20_145_Bold tablet:H6_18_145_Bold">
            {t("mypage.alert.logout")}
          </p>
        </section>
        <div className={styles.btns}>
          <button onClick={() => {
            setIsLogout(false);
          }} className={`H6_18_100_Medium tablet:B_16_145_Medium`}>
            {t("mypage.btn.logoutancel")}
          </button>
          <button onClick={LogoutHandler} className={`${styles.btnCancle} H6_18_100_Medium tablet:B_16_145_Medium`}>
            {t("mypage.logout")}
          </button>
        </div>
      </div>
    </div>
    /*// 알럿 레이어*/
  );
}