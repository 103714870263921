import { ConvertLocale } from "@modules/utils/ConvertLocale";
import { getAccessToken } from "@modules/utils/AccessToken";


export async function getGlobaleurSso(locale) {
  const res = await fetch(`/api/sso/globaleur`, {
    headers: {
       Authorization: getAccessToken(),
      "Accept-Language": ConvertLocale(locale),
    },
  });
  return { status: res.status, body: await res.json() };
}
