export function useDomain() {
    const envDomain = process.env.NEXT_PUBLIC_DOMAIN;
    return envDomain.includes('ttbb');
}

export function useKbpay() {
    const envDomain = process.env.NEXT_PUBLIC_DOMAIN;
    return envDomain.includes('kbtoz');
}

export function useIsKb() {
    const envDomain = process.env.NEXT_PUBLIC_DOMAIN;
    const isKb= envDomain.includes('kbtoz' || 'ttbb' )
    return isKb;
}

export function useChannel() {
    const isTTBB = useDomain();
    const isKBPay = useKbpay();

    if (isTTBB) {
        return "TTBB";
    } else if(isKBPay) {
        return "KBPAY";
    } else {
        return "TBZ";
    }
}

