import MainHeader from "./MainHeader";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
export default function ProfileHeader({
  setIsWithdrawPopup,
  setIsLogout
}) {
  const router = useRouter();
  // 다국어
  const {
    t
  } = useTranslation("common");
  return <>
      <MainHeader isShadow={true} className={`tablet:hidden`} setIsLogout={setIsLogout} />
      <header className="subHead tp1 hidden tablet:block
                   sticky left-0 top-0 w-full z-[12] bg-white
                   min-h-[56px] text-center">
        <a onClick={() => {
        router.back();
      }} className={`txtHide btnBack absolute w-[56px] h-[56px] block left-0 top-0 bg-ic_arrow_left_ou_b_g90 bg-no-repeat bg-[center_center]`}>
          뒤로가기
        </a>
        <div className="btnOutM hidden tablet:block absolute right-0 top-0">
          <a onClick={() => {
          setIsWithdrawPopup(true);
        }} className="SB_14_100_Medium text-Gray-300 h-[56px] flex items-center px-[20px] py-0 cursor-pointer">
              {t('mypage.member.withdrawal.txt.leave.now')}
          </a>
        </div>
      </header>
    </>;
}