import { Cookies } from "react-cookie-consent";
import Logo from "@components/layout/header/main/Logo";
import MainNav from "@components/layout/header/main/MainNav";
import { useScroll } from "@modules/hooks/useScroll";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import "dayjs/locale/ko";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
export default function MainHeader({
  className,
  isShadow,
  isLangPopup,
  setIsLangPopup,
  setIsLogout,
  openSearchBar,
  isRelative
}) {
  const [token, setToken] = useState(null);
  const scrollY = useScroll();
  const [globaleurFlag, setGlobaleurFlag] = useState(false);

  //KBTTBB 여행일정 (글로벌리어) 노출처리
  useEffect(() => {
    dayjs.locale("ko");
    dayjs.extend(utc);
    dayjs.extend(timezone);
    dayjs.tz.setDefault("Asia/Seoul");
    const flag = dayjs().isBefore(dayjs("2024-03-01"));
    setGlobaleurFlag(flag);
  }, []);
  useEffect(() => {
    const access_token = Cookies.get("access_token");
    setToken(access_token);
  }, []);
  return <>
      <header className={`${isRelative ? "relative" : "sticky"}
                  left-0 top-0 w-full bg-white z-[13] ${className}
                  ${scrollY > 0 && isShadow && !openSearchBar ? "shadow-[0_4px_18px_rgba(0,0,0,0.05)]" : ""}`}>
        <div className={`header flex h-[96px] items-center justify-between pr-[56px] pl-[80px] transition-all duration-[0.3s] 
                    tablet:px-4 tablet:h-[56px] mobile:pr-0`}>
          <Logo />
          <div className={`hMenu tablet:space-x-4 mobile:space-x-3.5 tablet:flex tablet:px-4`}>
            {globaleurFlag && <MainNav type='plan' />}
            <MainNav type="my" token={token} />
          </div>
        </div>
      </header>
    </>;
}